import React from 'react';

export const RoleContext = React.createContext();

export class RoleProvider extends React.Component {
    state = { user: this.props.value }
    render() {
        return (
            <RoleContext.Provider
                value={this.state.user}
            >
                {this.props.children}
            </RoleContext.Provider>
        )
    }
}

export const RoleConsumer = RoleContext.Consumer;