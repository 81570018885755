import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    SIGNIN_USER,
  SIGNOUT_USER,
  REFRESH_TOKEN
} from "../constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, subscribeTokenRefreshSuccess} from "../actions/Auth";
import axios from 'axios';

const getUser = async () =>
    await axios.get('/api/user/me')
    .then((res) => {
        if (!res.data.isAdmin){
            return false;
        }
        return {name: res.data.name || res.data.email || 'User'};
    })
    .catch(err => {
        return err});

const signInUserWithEmailPasswordRequest = async (params) =>
    await axios.post( '/api/auth/signin', params)
        .then(async response => {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+response.data['access_token'];
            const user = await getUser();
            if (!user){
                return {message: 'You\'re not allowed to access'};
            }
            return {user: await getUser(), token: response.data['access_token'], refreshToken: response.data['refresh_token']};

        })
        .catch(error => {
            // console.log(error.response);
            return error});


const refreshTokenRequest = async (params) =>
  await axios.post( '/api/auth/refresh', params)
    .then(async response => {
      axios.defaults.headers.common['Authorization'] = 'Bearer '+response.data['access_token'];

      return {token: response.data['access_token'], refreshToken: response.data['refresh_token']};

    })
    .catch(error => {
      // console.log(error.response);
      return false});



function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    const params = new URLSearchParams();
    params.append('email', email);
    params.append('password', password);
    params.append('client', 'Admin dashboard');
    params.append('version', '1.0');

    try {
        const signInUser = yield call(signInUserWithEmailPasswordRequest, params);
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            localStorage.setItem('user_id', signInUser.token);
            localStorage.setItem('user', JSON.stringify(signInUser.user));
            localStorage.setItem('refreshToken', signInUser.refreshToken);
            yield put(userSignInSuccess(signInUser.token, signInUser.user, signInUser.refreshToken));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}



function* refreshToken({payload}) {
  const cb = payload;
  const params = new URLSearchParams();
  params.append('refresh_token', localStorage.getItem('refreshToken'));
  params.append('client', 'Admin dashboard');
  params.append('version', '1.0');
  try {
    const refresh = yield call(refreshTokenRequest, params);

    if (!refresh) {
      yield call(signOut);
    } else {
      localStorage.setItem('user_id', refresh.token);
      localStorage.setItem('refreshToken', refresh.refreshToken);
      // cb(refresh.token);
      yield put(subscribeTokenRefreshSuccess(refresh.token, refresh.refreshToken));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
    try {
        localStorage.removeItem('user_id');
        localStorage.removeItem("user");
        localStorage.removeItem("refreshToken");
        yield put(userSignOutSuccess(signOutUser));

    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* refreshUserToken() {
  yield takeEvery(REFRESH_TOKEN, refreshToken);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        fork(signOutUser),
        fork(refreshUserToken)
    ]);
}
