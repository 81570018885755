import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';
import MainApp from '../app/index';
import SignIn from './SignIn';
import Recover from './Recover';
import {setInitUrl, subscribeTokenRefresh} from '../actions/Auth';
import asyncComponent from 'util/asyncComponent';
import {BASE_URL, API_KEY} from "../constants/strings";
import {RoleProvider} from "../app/contexts/RoleContext";
import axios from 'axios';
import Reset from './Reset';

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['x-api-key'] = API_KEY;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

class App extends Component {

  componentWillMount() {
    if (this.props.authUser) {
      axios.defaults.headers.common['Authorization'] = 'Bearer '+this.props.authUser;
      this.props.setInitUrl(this.props.history.location.pathname);
      const hasRefreshToken = this.props.refreshToken;
      const {subscribeTokenRefresh} = this.props;

      axios.interceptors.response.use(function (config) {
        return config;
      }, function(error) {
        const {config} = error;

        if (error.response.status === 403) {

          if (!hasRefreshToken){
            localStorage.removeItem("user_id");
            localStorage.removeItem("user");
            window.location.href = '/signin';
            return
          }

          return new Promise(resolve => {
            subscribeTokenRefresh(token => {
              config.headers.Authorization = `Bearer ${token}`;
              resolve(axios(config));
            });
          });
        }
        return Promise.reject(error);
      });
    } else {
      if (this.props.initURL === '') {
        this.props.setInitUrl('/admin');
      }
    }

  }

  componentWillUpdate() {
    const {subscribeTokenRefresh} = this.props;

    if (this.props.authUser){
      axios.defaults.headers.common['Authorization'] = 'Bearer '+this.props.authUser;
      const hasRefreshToken = this.props.refreshToken;
      axios.interceptors.response.use(function (config) {
        return config;
      }, function(error) {

        const {config} = error;

        if (error.response.status === 403) {
          if (!hasRefreshToken){
            localStorage.removeItem("user_id");
            localStorage.removeItem("user");
            window.location.href = '/signin';
            return
          }

          return new Promise(resolve => {
            subscribeTokenRefresh(token => {
              config.headers.Authorization = `Bearer ${token}`;
              resolve(axios(config));
            });
          });

        }
        return Promise.reject(error);
      });
    }
  }


  render() {

    const {match, location, locale, authUser, initURL, user} = this.props;

    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/admin'}/> );
      } else if (typeof initURL !== 'undefined') {
        return ( <Redirect to={initURL}/> );
      }
    }
    if (location.pathname==='/signin'){
      if (authUser){
        return <Redirect to={initURL} />
      }
    }

    const applyTheme = createMuiTheme(defaultTheme);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <RoleProvider value={user}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute path={`${match.url}admin`} authUser={authUser}
                                   component={MainApp}/>
                  <Route path='/signin' component={SignIn}/>
                  <Route path='/recover' component={Recover}/>
                  <Route path='/reset/:token' component={Reset}/>
                  <Route component={asyncComponent(() => import('../components/Error404'))}/>
                </Switch>
              </div>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </RoleProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {sideNavColor, locale} = settings;
  const {authUser, initURL, user, refreshToken} = auth;
  return {sideNavColor, locale, authUser, initURL, user, refreshToken}
};

export default connect(mapStateToProps, {setInitUrl, subscribeTokenRefresh})(App);

