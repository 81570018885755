import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import axios from 'axios';

import {
    hideMessage,
    showAuthLoader,
    hideAuthLoader,
    userSignUp,
} from '../actions/Auth';

class Reset extends React.Component {
    constructor() {
        super();
        this.state = {
            password: '',
            confirmPassword: '',
            message: '',
            showAlert: false,
            isError: false,
        }
    }

    componentDidMount() {
        if (!this.props.match.params.token) {
            this.props.history.push('/');
        }

        this.props.showAuthLoader()

        axios.get('/api/auth/resetpwd/check?token=' + this.props.match.params.token)
            .then(res => {
                this.props.hideAuthLoader();
            })
            .catch(err => {
                // let message = 'resetPasswordError';
                // if (err.response){
                //   if (err.response.status === 410){
                //     errorId = 'resetPasswordError.tokenUsed';
                //   } else if (err.response.status === 406){
                //     errorId = 'resetPasswordError.tokenExpired';
                //   } else if (err.response.status === 404){
                //     this.showNotification();
                //     errorId = 'resetPasswordError.tokenNotFound';
                //   }
                // }
                this.props.hideAuthLoader();
                this.setState({ message: err.message, showAlert: true, isError: true });
            });
    }

    componentDidUpdate() {
        if (this.state.showAlert) {
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                })
            }, 1000);
        }
    }

    handleSubmit = () => {
        if (this.state.password && this.state.confirmPassword) {
            this.props.showAuthLoader();
            axios.post('/api/auth/resetpwd/set?token=' + this.props.match.params.token + '&password=' + this.state.password)
                .then(res => {
                    this.props.hideAuthLoader();
                    this.setState({
                        message: res.data.message,
                        showAlert: true, 
                        isError: false, 
                    })
                })
                .catch(err => {
                    this.props.hideAuthLoader();
                    this.setState({
                        message: err.message,
                        showAlert: true,
                        isError: true
                    })
                })
        }
    };

    onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.handleSubmit();
        }
    };

    render() {
        const {
            email,
        } = this.state;
        const { loader } = this.props;
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Nestlé">
                            <img src={require("assets/images/logo_white.png")} alt="nestle lactalis" title="Nestlé" />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header">
                            <h1>Actualizar contraseña</h1>
                        </div>
                        {/* <div className="app-login-header"> */}
                        {/* <h4>Te enviaremos un correo electrónico con un enlace para recuperar tu acceso</h4> */}
                        {/* </div> */}

                        <div className="app-login-form">
                            <form>

                                <TextField
                                    type="password"
                                    onChange={(event) => this.setState({ password: event.target.value })}
                                    label={<IntlMessages id="appModule.password" />}
                                    fullWidth
                                    defaultValue={email}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="password"
                                    onChange={(event) => this.setState({ confirmPassword: event.target.value })}
                                    label={<IntlMessages id="appModule.confirmPassword" />}
                                    fullWidth
                                    error={this.state.password && this.state.password !== this.state.confirmPassword}
                                    defaultValue={email}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                    onKeyDown={this.onKeyDown}
                                />

                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    <Button variant="contained" onClick={this.handleSubmit} color="primary" disabled={this.state.password !== '' && this.state.password !== this.state.confirmPassword}>
                                        <IntlMessages
                                            id="resetPassword" />
                                    </Button>
                                    <Link to="/signin">
                                        <IntlMessages id="backToSignIn" />
                                    </Link>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>

                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                }
                {this.state.showAlert && ( this.state.isError ? NotificationManager.error(this.state.message) : NotificationManager.success(this.state.message))}
                <NotificationContainer />
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    const { loader } = auth;
    return { loader }
};

export default connect(mapStateToProps, {
    userSignUp,
    hideMessage,
    showAuthLoader,
    hideAuthLoader
})(Reset);
