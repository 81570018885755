import React from 'react';

const Footer = () => {
    return (
      <footer className="app-footer">
        <span> Lactalis Group &copy; {(new Date()).getFullYear()}</span>
        <span className="float-right">Powered by <a href="http://dribba.com" rel="noopener noreferrer" target="_blank">Dribba</a></span>
      </footer>
    );
  }
;

export default Footer;
